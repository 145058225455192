<template>
  <page-skeleton :products="false" :steps="false">
    <b-container fluid class="i-skeleton">
      <b-row>
        <b-col
            order="1"
            order-md="0"
            cols="12" md="6"
            class="text-left px-0 photo-section">
          <b-img-lazy
              src="https://ik.imagekit.io/isotronic/contact/vial-with-geometric-defects_RyrTNrNs8Q.png?updatedAt=1635422859019"
              class="section-img"
              :alt="$t('contact.alt')"
          />
          <contact-card/>
        </b-col>
        <b-col cols="12" md="6" class="my-5 my-md-auto">
          <b-row class="justify-content-left">
            <b-col cols="12" lg="10" xl="9">
              <div class="mb-4">
                <h2 class="text-uppercase">
                  {{ $t('contact.header') }}
                </h2>
                <div class="mt-3 i-font-text-light">
                  {{ $t('contact.subheader') }}
                </div>
              </div>
              <contact-form/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import ContactForm from "@/components/contact/ContactForm"
import ContactCard from "@/components/contact/ContactCard"

export default {
  components: {
    PageSkeleton,
    ContactForm,
    ContactCard
  },
  metaInfo() {
    return {
      title: this.$t('contact.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('contact.meta.description')}
      ]
    }
  },
}
</script>

<style lang="sass" scoped>
.photo-section
  max-height: 400px
  overflow: hidden
  @media screen and (min-width: $sm)
    max-height: 510px
  @media screen and (min-width: $md)
    max-height: none
  height: 700px
  @media screen and (min-width: $lg)
    height: 750px
  @media screen and (min-width: $xl)
    height: 870px

.photo-section::before
  background: linear-gradient(180deg, rgba(235, 110, 111, 0) 0%, rgba(235, 110, 111, 0.0086472) 6.67%, rgba(235, 110, 111, 0.03551) 13.33%, rgba(235, 110, 111, 0.0816599) 20%, rgba(235, 110, 111, 0.147411) 26.67%, rgba(235, 110, 111, 0.231775) 33.33%, rgba(235, 110, 111, 0.331884) 40%, rgba(235, 110, 111, 0.442691) 46.67%, rgba(235, 110, 111, 0.557309) 53.33%, rgba(235, 110, 111, 0.668116) 60%, rgba(235, 110, 111, 0.768225) 66.67%, rgba(235, 110, 111, 0.852589) 73.33%, rgba(235, 110, 111, 0.91834) 80%, rgba(235, 110, 111, 0.96449) 86.67%, rgba(235, 110, 111, 0.991353) 93.33%, #EB6E6F 100%)
  content: ''
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  display: inline-block
  mix-blend-mode: multiply

.section-img
  opacity: 0
  width: 100% !important
  object-position: left !important
  @media screen and (min-width: $sm)
    opacity: 1
  @media screen and (min-width: $md)
    height: 100% !important
    object-fit: cover !important
  @media screen and (min-width: $xl)
    object-fit: contain !important

.contact-card
  border: 1px solid $primary-color
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  border-radius: 20px
  width: calc(100% - 60px)
  @media screen and (min-width: $sm)
    border: 1px solid black
    width: 400px

.contact-table
  padding: 20px 5px !important

  /deep/ tr
    line-height: 35px
</style>
