<template>
  <div>
    <b-form @submit="onSubmit">
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group
              id="input-name-field"
          >
            <label for="input-name" class="text-uppercase i-primary i-text-smaller">
              {{ $t('contact.field1') }}
            </label>
            <b-form-input
                id="input-name"
                class="input-box h-box"
                v-model="form.name"
                :state="nameState"
                type="text"
                data-lpignore="true"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
              id="input-email-field"
          >
            <label for="input-email" class="text-uppercase i-primary i-text-smaller">
              {{ $t('contact.field2') }}
            </label>
            <b-form-input
                id="input-email"
                v-model="form.email"
                type="text"
                :state="emailState"
                class="input-box h-box"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
              id="input-name-company"
          >
            <label for="input-company">
              <div class="i-text-smaller">
                <span class="text-uppercase i-primary">
                  {{ $t('contact.field3') }}
                </span>
                <span class="i-secondary i-font-text-light">
                  - {{ $t('contact.optional') }}
                </span>
              </div>
            </label>
            <b-form-input
                id="input-company"
                v-model="form.companyName"
                class="input-box h-box"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6">
          <b-form-group
              id="select-region-field"
              label-for="select-region"
          >
            <label for="select-region">
              <div class="i-text-smaller">
                <span class="text-uppercase i-primary ">
                  {{ $t('contact.field4.name') }}
                </span>
                <span class="i-secondary i-font-text-light">
                  - {{ $t('contact.optional') }}
                </span>
              </div>
            </label>
            <b-form-select id="select-region"
                           v-model="form.region"
                           class="input-box h-box"
                           :options="regions">
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              id="select-product-field"
          >
            <label for="select-product">
              <div class="i-text-smaller">
                <span class="text-uppercase i-primary ">
                  {{ $t('contact.field5.name') }}
                </span>
                <span class="i-secondary i-font-text-light">
                  - {{ $t('contact.optional') }}
                </span>
              </div>
            </label>
            <b-form-select id="select-product"
                           class="input-box h-box"
                           v-model="form.productInterest"
                           :options="products"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              id="message-field"
          >
            <label for="message" class="text-uppercase i-primary i-text-smaller">
              {{ $t('contact.field6.name') }}
            </label>
            <b-form-textarea
                id="textarea"
                v-model="form.message"
                size="sm"
                rows="3"
                :state="messageState"
                :formatter="formatter"
                class="input-box m-box"
            ></b-form-textarea>
            <div class="i-secondary i-text-smaller mt-1">
              {{ form.message.length }} / 500
            </div>
          </b-form-group>
        </b-col>
        <input type="text" v-model="pass" name="a_password" style="display:none !important" tabindex="-1"
               autocomplete="off">
        <b-col cols="auto">
          <b-button type="submit" class="i-primary-button text-uppercase">
            {{ $t('contact.button') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div class="w-100 i-primary mt-2" v-if="internalError">
      Internal error on our side. Please try again or send us an email.
    </div>
    <div class="w-100 mt-2 text-success" v-if="success">
      Mail successfully submitted. Thanks and we will get back to you!
    </div>
    <div class="w-100 mt-2 i-primary" v-if="formError">
      Please fill out all mandatory fields before submitting
    </div>
  </div>
</template>

<script>
import axios from "axios";
// const BASE = `${window.location.protocol}//${window.location.hostname}/mail_gateway`
const BASE = process.env.NODE_ENV === 'development'
    ? `${window.location.protocol}//${window.location.hostname}:3000`
    : `${window.location.protocol}//${window.location.hostname}/mail_gateway`

export default {
  data() {
    return {
      pass: '',
      form: {
        email: '',
        name: '',
        companyName: '',
        region: '',
        productInterest: '',
        message: ''
      },
      regions: [
        {value: 'Africa', text: this.$t('contact.field4.regions.region1')},
        {value: 'Asia', text: this.$t('contact.field4.regions.region2')},
        {value: 'Australia', text: this.$t('contact.field4.regions.region3')},
        {value: 'Europe', text: this.$t('contact.field4.regions.region4')},
        {value: 'North America', text: this.$t('contact.field4.regions.region5')},
        {value: 'South America', text: this.$t('contact.field4.regions.region6')}
      ],
      products: [
        {value: 'Everything', text: this.$t('contact.field5.products.product1')},
        {value: 'Central', text: this.$t('contact.field5.products.product2')},
        {value: 'Checker', text: this.$t('contact.field5.products.product3')},
        {value: 'Handler', text: this.$t('contact.field5.products.product4')},
        {value: 'Other', text: this.$t('contact.field5.products.product5')},
      ],
      submitted: false,
      success: false,
      internalError: false,
      formError: false
    }
  },
  computed: {
    nameState() {
      return this.submitted ? this.form.name.length > 0 : null
    },
    emailState() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return this.submitted ? re.test(String(this.form.email).toLowerCase()) : null
    },
    messageState() {
      return this.submitted ? this.form.message.length > 0 : null
    },
  },
  methods: {
    formatter(value) {
      return value.substring(0, 500)
    },
    onSubmit(event) {
      event.preventDefault()
      if (this.pass.length > 0) {
        //we have a spam bot...don't return
        return
      }
      this.submitted = true
      this.success = false
      this.internalError = false
      this.formError = false

      if (!this.messageState || !this.nameState || !this.emailState) {
        //user has not filled out all fields
        this.formError = true
        return
      }

      let formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('email', this.form.email);
      formData.append('companyName', this.form.companyName);
      formData.append('region', this.form.region);
      formData.append('productInterest', this.form.productInterest);
      formData.append('message', this.form.message);

      axios
          .post(`${BASE}/submit_form`, formData)
          .then(() => {
            this.submitted = false;
            this.success = true;
            this.form.name = '';
            this.form.email = '';
            this.form.companyName = '',
            this.form.region = '',
            this.form.productInterest = '',
            this.form.message = ''
          })
          .catch(err => {
            this.internalError = true
            console.log(err);
          });
    }
  }
}
</script>

<style lang="sass" scoped>
.indetext
  position: relative
  z-index: -1

.input-box
  border-radius: 15px
  font-size: 14px
  line-height: 14px !important
  border: 1px solid #B8B8BA
  color: #393840

#select-region, #select-product
  &:hover
    cursor: pointer!important

.h-box
  height: 50px

.m-box
  padding: 20px

.input-box:focus
  border: 2px solid $primary-color !important
  box-shadow: none !important
  color: #393840
</style>

<style lang="sass">

.is-invalid
  border: 1px solid $primary-color !important
</style>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  -webkit-text-fill-color:  #393840;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  -webkit-text-fill-color:  #393840;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
